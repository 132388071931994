import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  // counterSetCount: auths.SETCOUNT,
  // counterIncrement: auths.INCREMENT,
  // counterDecrement: auths.DECREMENT,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
